<template>
    <div class="pb-10 h-100 ml-2 mb-20">
        <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <div class="breadcrumbactive">
                <div>
                    <span class="pointer" @click="goToCompanyList">Company</span>
                    <span>Departments</span>
                    <div> List View</div>
                </div>
            </div>
            <div>
                <div class="d-flex align-items-center mr-2">
                    <button @click="showFilters()" type="button" class="btn btn-smm btn-new-secondary px-2"
                        style="border: 0 !important;border-radius: 6px !important;">
                        <img src="/static/images/filter.svg" />
                        Filter
                    </button>
                    <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="button" class="btn btn-smm btn-danger ml-3" style="border-radius: 6px !important;"
                        @click="showDeleteDepartment()" :disabled="department_unit_selected.length < 1">
                        <i class="icon icon-minus fs-12 text-white"></i>
                        Delete
                    </button>
                    <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="button" class="btn btn-smm text-white btn-success ml-3"
                        style="border-radius: 6px !important;" @click="goToCreateNewDepartment()">
                        <i class="icon icon-plus fs-12 text-white" style="color: #fff !important;"></i>
                        Create New
                    </button>
                </div>
            </div>
        </div>
        <div class="pr-2">
            <div class="w-100">
                <div class="d-flex align-items-center">
                    <!-- <div class="d-flex align-items-center">
                        <div class="header-slant-tab">
                            <span class="text-secondary fs-14">List View</span>
                        </div>
                        <div class="header-slant-tab-border"></div>
                    </div> -->
                    <div class=" mb-2 d-flex align-items-center w-100" style="position: relative;">
                        <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                        <i v-if="search_key !=''" class="icon icon-close fs-20" style="position: absolute; right: 10px;color: #707070 !important;cursor: pointer;" @click="clearSearch"></i>
                        <input type="text" class="search-input-field" v-model="search_key" @input="getListViewData"
                            placeholder="Search by Department Name, Code, Business Units ... " />
                    </div>
                </div>
                <div class="table-start" style="min-height: 30em;">
                    <div class="d-flex p-2">
                        <div class="d-flex p-2" style="border-right: 1px solid #000;">
                            <span class="fw-500">Company name:&nbsp;</span>
                            <span class="fw-500 pr-3 text-capitalize" style="color:#00448b">{{company_name || "-"}}</span>
                        </div>
                        <div class="d-flex p-2">
                            <span class="fw-500 pl-3">Date of Incorporation:&nbsp;</span>
                            <span class="fw-500" style="color:#00448b">{{date_of_incorporation || "-"}}</span>
                        </div>
                    </div>
                    <table class="admin-new-table w-100" style="border-collapse: collapse; position: relative;"
                        width="100%">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    <input v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="checkbox" v-model="check_all" @change="checkAll" name="select_all" />
                                </th>
                                <th>
                                    <el-tooltip style="visibility: hidden; opacity: 0;" class="item" effect="dark" content="Edit" placement="top">
                                        <button class="pointer btn-icon pt-3"
                                            style="padding:0px!important;margin:0 auto;display:block;">
                                            <img src="/static/images/edit-new.svg"
                                                height="15px;">
                                        </button>
                                    </el-tooltip>
                                </th>
                                <th>
                                    started on
                                </th>
                                <th>
                                    Department Name
                                </th>
                                <th>
                                    business units
                                </th>
                                <th>
                                    department head
                                </th>
                                <th>
                                    Primary Email
                                </th>
                                <th>
                                    Phone Number
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="ajax_call_in_progress">
                                <td colspan="7">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!ajax_call_in_progress && list_view_data.length == 0" class="text-center font-weight-bold">
                                <td colspan="7">Department are Not Found</td>
                            </tr>
                            <tr v-else v-for="(department, index) in list_view_data" :key="department.id+index">
                            <!-- <tr> -->
                                <td class="text-center">
                                    <input v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="checkbox" v-model="department.checked" @change="selectDepart($event, department.id, index)"
                                        name="select_one" />
                                </td>
                                <td>
                                    <el-tooltip v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="item" effect="dark" content="Edit" placement="top">
                                        <button class="pointer btn-icon pt-3" @click="goToEditView(department.id)"
                                            style="padding:0px!important;margin:0 auto;display:block;">
                                            <img src="/static/images/edit-new.svg"
                                                height="15px;">
                                        </button>
                                    </el-tooltip>
                                </td>
                                <td>{{department.started_on || "-"}}</td>
                                <td class="text-truncate" :title="department.name">
                                    <span class="text-secondary pointer" @click="goToViewDepartment(department.id)">
                                        {{department.name || "-"}}
                                    </span>
                                </td>
                                <td>{{department.business_unit_name || "-"}}</td>
                                <td>{{department.department_head_name || "-"}}</td>
                                <td style="text-transform: lowercase !important;">{{department.primary_email || "-"}}</td>
                                <td>{{department.phone_number || "-"}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="!ajax_call_in_progress" class="d-flex justify-content-end align-items-center bg-white py-2 px-4 pb-10"
                    style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
                    <multiselect class="diginew-multiselect"
                        :options="pageLimit" :close-on-select="true" v-model.number="limit"
                        open-direction="top" :show-labels="false" placeholder=""
                        style="width: 4.0em !important;padding-right: 10px!important;padding-top: 10px !important;"></multiselect>
                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg"
                        style="margin-left: -20px;">
                    <span class="page-info pr-2 pl-6 fw-500"
                        style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                    <button @click="firstPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                        <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                            <i class="icon-chevron-double-left"></i>
                        </el-tooltip>
                    </button>
                    <button @click="previousPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                            <i class="icon icon-chevron-left"></i>
                        </el-tooltip>
                    </button>
                    <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} -
                        {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                    <button @click="nextPage()" :disabled="skip_temp >= total" :class="{'not-allowed' : skip_temp >= total }"
                        class="pagination-list mt-1 mr-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                            <i class="icon icon-chevron-right"></i>
                        </el-tooltip>
                    </button>
                    <button @click="lastPage()" class="mt-1 pointer pagination-list" :disabled="temp_total == total" :class="{'not-allowed' : temp_total == total}">
                        <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                            <i class="icon-chevron-double-right"></i>
                        </el-tooltip>
                    </button>
                </div>
            </div>
        </div>
        <delete-department-modal @hide-delete-department-modal="HideDeleteDepartmentModal" @deleteDepartSuccess="deleteDepartSuccess" v-if="deletedepartmentmodal"
            modal_name="Delete_Department_Modal"></delete-department-modal>
        <div v-if="display_filters" class="scm-filters fadeInDown" :class="{'fadeInUp':hide_filters == true}"
            style="background-color: #e6ecff !important;height: 250px !important;">
            <filter-department-data :showfilterData="showfilterData" @hideFilters="hideFilters" @UpdateByFilter="UpdateByFilter"></filter-department-data>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import FilterDepartmentData from './FilterDepartmentData'
    import DeleteDepartmentModal from './DeleteDepartmentModal'
    import { SweetModal } from 'sweet-modal-vue';
    import {HalfCircleSpinner} from 'epic-spinners';
    import companies from '../mixins/companies'
    import globals from '../globals';
    import axios from 'axios';
    export default {
        components: {
            FilterDepartmentData,
            DeleteDepartmentModal,
            HalfCircleSpinner,
            SweetModal
        },
        mixins: [companies],
        data() {
            return {
                skip: 0,
                limit: 10,
                count: 0,
                start: 0,
                search_key: '',
                warning_msg: '',
                success_msg: '',
                end: 0,
                total: 0,
                skip_temp: 0,
                temp_total: 0,
                display_filters: false,
                hide_filters: false,
                showfilterData: {
                    started_on:"",
                    department_head:[],
                    business_unit_id:[],
                    department_name:[]
                },
                filterData: {
                    started_on:"",
                    department_head:[],
                    business_unit_id:[],
                    department_name:[]
                },
                deletedepartmentmodal: false,
                check_all: false,
                list_view_data: [],
                department_unit_selected: [],
                company_name: '',
                date_of_incorporation: '',
                ajax_call_in_progress: false,
                pageLimit: [],
            }
        },
        methods: {
            clearSearch(){
                this.search_key = ""
                this.getListViewData();
            },
            showDeleteDepartment() {
                this.deletedepartmentmodal = true
                setTimeout(() => {
                    this.$modal.show('Delete_Department_Modal')
                }, 500)
            },
            goToCompanyList() {
                this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
            },
            HideDeleteDepartmentModal() {
                this.deletedepartmentmodal = false;
            },
            deleteDepartSuccess() {
                this.deleteDepartment(this.department_unit_selected);
            },
            goToEditView(id) {
                this.$router.push(`/departmentunit/edit/cmpid/${this.$route.params.company_id}/${id}`);
            },
            goToViewDepartment(id) {
                this.$router.push(`/departmentunit/view/cmpid/${this.$route.params.company_id}/${id}`);
            },
            showFilters() {
                if (this.display_filters) {
                    this.hide_filters = true
                    setTimeout(() => {
                        this.display_filters = false
                        this.hide_filters = false
                    }, 500);
                } else {
                    this.display_filters = true
                }
            },
            UpdateByFilter(params){
                this.skip = 0;
                this.limit = 10;
                this.showfilterData = {
                    started_on: params.value1.started_on ? params.value1.started_on.split('/')[2]+'-'+params.value1.started_on.split('/')[1]+'-'+params.value1.started_on.split('/')[0] : '',
                    department_head: params.value1.department_head,
                    business_unit_id: params.value1.business_unit_id,
                    department_name: params.value1.department_name,
                }
                this.filterData = {
                    started_on: params.value.started_on ? params.value.started_on.split('/')[2]+'-'+params.value.started_on.split('/')[1]+'-'+params.value.started_on.split('/')[0] : '',
                    department_head: params.value.department_head,
                    business_unit_id: params.value.business_unit_id,
                    department_name: params.value.department_name,
                }
                this.getListViewData()
            },
            // removeAllChips(){
            //     this.showfilterData = {
            //         started_on:"",
            //         code:[],
            //         country:[],
            //         business_unit:[]
            //     },
            //     this.filterData = {
            //         started_on:"",
            //         code:[],
            //         country:[],
            //         business_unit:[]
            //     },
            //     EventBus.$emit('removeAllchip')
            //     this.loadVendors()
            // },
            hideFilters() {
                this.hide_filters = true
                setTimeout(() => {
                    this.display_filters = false
                    this.hide_filters = false
                }, 500);
            },
            goToCreateNewDepartment() {
                this.$router.push({
                    path: '/departmentunit/create/cmpid/'+this.$route.params.company_id
                })
            },
            previousPage() {
                this.temp_total = 0
                this.skip_temp = 0
                if (this.skip == 0) {
                    return;
                }
                this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
                this.getListViewData();
            },
            nextPage() {
                this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp;
                this.getListViewData();
            },
            firstPage() {
                this.temp_total = 0
                this.skip = 0
                this.limit = this.limit
                this.list_view_data = []
                this.getListViewData();
            },
            lastPage() {
                this.skip_temp = 0
                this.skip = 0
                this.temp_total = this.total
                let no_of_pages = Math.ceil(this.total / this.limit)
                this.skip_temp = (no_of_pages * this.limit) - this.limit
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp
                this.getListViewData();
            },
            selectDepart(event, id) {
                if (event.target.checked) {
                    this.department_unit_selected.push(id);
                } else {
                    let index = this.department_unit_selected.indexOf(id);
                    if (index > -1) {
                        this.department_unit_selected.splice(index, 1);
                    }
                }
                if (this.department_unit_selected.length === this.list_view_data.length) {
                    this.check_all = true;
                } else {
                    this.check_all = false;
                }
            },
            checkAll(event) {
                if (event.target.checked === true) {
                    this.list_view_data.forEach((org, index) => {
                        this.list_view_data[index].checked = true;
                        this.department_unit_selected.push(org.id);
                    })
                } else {
                    this.list_view_data.forEach((org, index) => {
                        this.list_view_data[index].checked = false;
                    })
                    this.department_unit_selected = [];
                }
            },
            async getCompanyInfo(id) {
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getCompanyById(id);
                    if(response.status_id == 1) {
                        this.company_name = response.response.name;
                        if(response.response.date_of_incorporation == ''){
                            this.date_of_incorporation = response.response.creation_time;
                        }else {
                            this.date_of_incorporation = response.response.date_of_incorporation;
                        }
                        localStorage.setItem('company_department_domain_name', response.response.domain);
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async deleteDepartment(id) {
                let params = {
                    id: id
                };
                await axios.delete(globals.AUTH_SERVICE + `/departments/delete`, {
                    data: params
                }).then((response) => {
                    if(response.data.status_id == 1) {
                        this.deletedepartmentmodal = false;
                        this.success_msg = response.data.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getListViewData();
                        }, 2000);
                    }
                }).catch((err) => {
                    this.warning_msg = err.response.data.reason;
                    this.$refs.warning_modal.open();
                });
            },
            async getListViewData() {
                this.ajax_call_in_progress = true;
                this.department_unit_selected = [];
                this.list_view_data = [];
                this.check_all = false;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                        search_key: this.search_key,
                        company_id: this.$route.params.company_id,
                        name: this.filterData.department_name,
                        started_on: this.filterData.started_on,
                        business_unit_id: this.filterData.business_unit_id,
                        department_head: this.filterData.department_head
                    }
                    let response = await this.getListDepartmentUnit(params);
                    if(response.status_id == 1) {
                        this.list_view_data = response.response;
                        this.list_view_data.forEach((depart, index) => {
                            this.list_view_data[index].checked = false;
                        })
                        this.total = response.count;
                    }
                    this.ajax_call_in_progress = false;
                } catch (err) {
                    this.ajax_call_in_progress = false;
                }
            },
        },
        mounted() {
            this.getCompanyInfo(this.$route.params.company_id);
            this.getListViewData();
            // for (let i = 1; i <= 50; i++) {
            //     i += 9
            //     this.pageLimit.push(i);
            // }
        },
        computed: {
            getSkipCount() {
                if (this.total == 0) {
                    return 0
                } else {
                    let skip_count = this.skip > this.total ? this.total : this.skip + 1;
                    return skip_count;
                }
            },
            loggedInUser() {
				return this.$store.state.loggedInUser;
			},
        },
        created() {
            for (let i = 10; i <= 50; i+=10) {
                this.pageLimit.push(i);
            }
        },
        watch: {
            limit: function(){
                this.getListViewData()
            },
        },
    }
</script>
<style scoped>
    .table-view-start {
        background-color: #fff;
    }

    .search-input-field {
        padding: 8px 9.4px 8px 30px;
        border-radius: 18px !important;
        background-color: #fff;
        width: 100%;
    }

    .table-start {
        background-color: #fff;
        padding-top: 5px;
        border-radius: 8px;
        overflow-x: auto !important;
    }

    .scm-filters {
        height: 435px !important;
        overflow: visible !important;
    }
    .not-allowed{
        cursor: no-drop !important;
    }
    .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px !important;
    }
</style>